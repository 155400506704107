import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import CardContainer from './CardContainer';
import ContentCopy from './ContentCopy';
import './ArticleViewer.css';

function ArticleViewer() {
  const [articles, setArticles] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then(data => setArticles(data))
      .catch(error => console.error('Error loading the articles:', error));

    const handleKeyPress = (event) => {
      switch (event.keyCode) {
        case 38: // up arrow
        case 37: // left arrow
          event.preventDefault();
          setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : articles.length - 1));
          break;
        case 40: // down arrow
        case 39: // right arrow
          event.preventDefault();
          setActiveIndex(prevIndex => (prevIndex + 1) % articles.length);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [articles.length]);

  const handlers = useSwipeable({
    onSwipedLeft: () => setActiveIndex(prevIndex => (prevIndex + 1) % articles.length),
    onSwipedRight: () => setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : articles.length - 1)),
    onSwipedUp: () => setActiveIndex(prevIndex => (prevIndex + 1) % articles.length),
    onSwipedDown: () => setActiveIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : articles.length - 1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <div className="article-viewer" {...handlers}>
      <CardContainer 
        article={articles[activeIndex]} 
        activeIndex={activeIndex} 
        totalArticles={articles.length}
      />
      <ContentCopy article={articles[activeIndex]} />
    </div>
  );
}

export default ArticleViewer;
