import React from 'react';
import './ContentCopy.css';
import { hashString } from './utils/hashFunction';

function ContentCopy({ article }) {
    const gridSize = 8;  // Keep the 8x8 grid
    const cellSize = 80;  // Increase cell size for a larger SVG
    const colorPalette = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#d62828', '#023e8a', '#0077b6'];  // A predefined set of modern colors

    const generateSvgContent = () => {
        const shapes = [];
        const baseHash = hashString(article.content);
        for (let i = 0; i < gridSize * gridSize; i++) {
            const seed = (baseHash + i) % 3;
            const scale = 2;
            const size = (50+((baseHash >> (i % 8)) % (cellSize / 2)) + 10)*scale;  // Adjust size range
            const x = (i % gridSize) * cellSize + (cellSize - size) / 2;
            const y = Math.floor(i / gridSize) * cellSize + (cellSize - size) / 2;
            const color = colorPalette[i % colorPalette.length]; // Cycle through the color palette

            switch (seed) {
                case 0:
                    shapes.push(<rect key={i} x={x} y={y} width={size} height={size} fill={color} />);
                    break;
                case 1:
                    shapes.push(<circle key={i} cx={x + size / 2} cy={y + size / 2} r={size / 2} fill={color} />);
                    break;
                case 2:
                    shapes.push(<polygon key={i} points={`${x},${y} ${x + size},${y} ${x + size / 2},${y + size}`} fill={color} />);
                    break;
                default:
                    break;
            }
        }
        return shapes;
    };

    return (
        <div className="content-copy">
            {article ? (
                <svg width={gridSize * cellSize} height={gridSize * cellSize} viewBox={`0 0 ${gridSize * cellSize} ${gridSize * cellSize}`}>
                    {generateSvgContent()}
                </svg>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default ContentCopy;
