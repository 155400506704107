import React from 'react';
import './App.css';
import ArticleViewer from './ArticleViewer';

function App() {
  return (
    <div className="App">
      <ArticleViewer />
    </div>
  );
}

export default App;
