import React from 'react';
import './CardContainer.css';

function CardContainer({ article, activeIndex, totalArticles }) {
  return (
    <div className="card-container">
      {article ? (
        <div>
          <div className="article-counter">
              {activeIndex + 1}/{totalArticles}
          </div>          
          <h1>{article.title}</h1>
          <h4>By {article.author}, {article.date_time}</h4>
          <p>{article.content}</p>
          <a href={article.link} target="_blank" rel="noopener noreferrer">Read more</a>
          <a href={article.external_link} target="_blank" rel="noopener noreferrer">External link</a>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default CardContainer;
